img-gradient {
  position: relative;
  display: inline-block;

  height: 100%;
  border-radius: 4px;
}

.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 4px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
@media screen and (max-width: 900px) {
  .img-gradient:after {
    width: 100%;
    height: 100%;
  }
}
