.no-scroll {
  overflow: hidden;
}
.desktop-navigation-container {
  width: 100%;
  display: flex;
  margin-top: -5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.desktop-drawer-items-list {
  width: 85%;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: start;
}
.desktop-drawer-list-items-text {
  margin-left: 5px;

  text-decoration: none;
  color: var(--primary-black);
  font-size: 14px;
  font-family: "MyCustomFont", sans-serif;
}
