.flex-center {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.wrapper {
 width: 100vw;
 height: 100vh;

}

.card {
  width: 100%;
  height: 100%;
  position: relative;

  box-shadow: 0 10px 15px rgba(9, 8, 8, 0.05);
  overflow: hidden;
}

.shimmer {
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
  animation: shimmer 2s infinite;
  border-top: 1px solid rgba(57, 56, 56, 0.1);
  background: linear-gradient(to right, transparent, rgba(93, 93, 93, 0.3), transparent);
}

.card-content {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: rgba(112, 111, 111, 0.1);
}


@keyframes shimmer {
  
  100% {
    transform: translateX(100%);
  }
}
