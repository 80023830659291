.image-box {
	position: relative;
	height: 580px;
}

.image-box__background,
.image-box__overlay {
	position: relative;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.image-box__background {
	z-index: 1;
	height: 250px;
}

.image-box__overlay {
	z-index: 2;
}
.image-box__content {
	position: relative;
	bottom: 100;
	z-index: 3;
	align-items: center;
	/* min-height: 30vh; */
	display: flex;
	align-items: center;
	justify-content: center;
}
@media screen and (max-width: 900px) {
	.image-box {
		height: 500px;
	}
}
@media screen and (max-width: 600px) {
	.image-box__background {
		height: 25vh;
	}
}
