:root {
  --primary-color: #ff6840;
  --secondary-color: #0b3954;
  --accent-color: #959595;
  --primary-background-color: #fff;
  --secondary-background-color: #f8f8f8;
  --text-primary-color: #282828;
  --text-secondary-color: #686868;
  --text-ternary-color: #c1c1c1;
  --overlay-color: rgba(0, 0, 0, 0.4);
  --overlay-color-slider: rgba(0, 0, 0, 0.6);
  --heading-color: #afde1e;
  --primary-black: black;
  --secondary-button-color: #67c4dd;
  --error-text-color: red;
  --success-color: green;
  --box-color: rgb(157, 238, 133);
  --card-background-color: #d2d0d0;
  --card-bg-color: #d7d2d2;
  --sales-secondary-color: #e92334;
  --sales-overlay-color: #2c2c2c;
  --tsh-discount-color: #e23a87;
  --icon-color:#39abd4;
  

  --font-family: "Roboto", sans-serif;

  --font-size-h1: 32px;
  --font-size-h2: 28px;
  --font-size-h3: 24px;
  --font-size-h4: 20px;
  --font-size-h5: 16px;
  --font-size-h6: 14px;
  --font-size-body: 16px;
  --font-size-small: 12px;

  --margin-small: 8px;
  --margin-medium: 16px;
  --margin-large: 24px;

  --padding-small: 8px;
  --padding-medium: 16px;
  --padding-large: 24px;
}
