@font-face {
  font-family: "MyCustomFont";
  src: url("../../../assets/fonts//Aileron-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.login-container {
  background-color: var(--primary-background-color);
  padding: 5px 20px 20px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 450px;
  text-align: center;
  outline: none;
  border: none;
}
.login-close-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid var(--text-ternary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--primary-background-color);
}
.login-header {
  flex: 4;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topbar-container {
  flex: 4;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}
.login-logo {
  /* height: 40px; */
  width: 130px;
  object-fit: cover;
}

.login-container h2 {
  font-size: 20px;
  font-family: "MyCustomFont", sans-serif;
}

.form {
  display: flex;
  flex-direction: column;
}

label {
  text-align: left;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "MyCustomFont", sans-serif;
}

form > select {
  width: 100%;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid var(--text-ternary-color);
  border-radius: 5px;
  font-size: 14px;
}
form > input[type="tel"] {
  width: 95%;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid var(--text-ternary-color);
  border-radius: 5px;
  font-size: 14px;
}

.privacy-policy {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy:hover {
  text-decoration: underline;
}

.phone-number-input-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
}

.react-select__control {
  margin-bottom: 16px;
}

.whatsapp-text-container {
  /* height: 100%; */
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  margin-bottom: 10px;
  margin-top: 10px;

  font-family: "MyCustomFont", sans-serif;
}

.error-text {
  position: absolute;
  color: var(--error-text-color);
  bottom: -24px;
  right: 0;
  left: 0;
  font-size: 10px;
  padding: 0;
  line-height: 2px;
  display: flex;
  align-items: center;
  justify-content: start;
}
.disclaimer {
  font-size: 12px;
  color: var(--text-secondary-color);
  width: 100%;
  text-align: left;
  font-family: "MyCustomFont", sans-serif;
}
.country-select option {
  padding: 8px 16px;
  background-color: var(--primary-background-color);
  color: var(--text-primary-color);
  border-radius: 0 !important;
}

.whatsapp-update {
  font-size: 12px;
  color: var(--text-secondary-color);
  width: 100%;
  text-align: left;
  font-family: "MyCustomFont", sans-serif;
  line-height: 0px;
  padding: 0px;
  background-color: var(--text-primary-color);
}
input[type="checkbox" i] {
  margin: 0px 5px 0px 0px !important;
}
.login-button {
  padding: 15px 0px;
  width: 50%;
  align-self: center;
}
.login-country-selector {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  outline: none;
  border: none;
}
.country-select-container {
  max-width: 400px;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.select-wrapper {
  position: relative;
  width: 100%;
}

.country-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 15px 16px;
  padding-top: 24px;
  border-left: 1.5px solid var(--text-ternary-color);
  border-top: 1.5px solid var(--text-ternary-color);
  border-right: 1.5px solid var(--text-ternary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 16px;
  color: var(--text-primary-color);
  box-sizing: border-box;
  appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0iIzAwMDAwMCI+PHBhdGggZD0iTTEyIDE1LjU4TDYuNTkgMTBMNS41OSAxMS4xMSAxMiAxNy41MiAxOC40MSAxMS4xMSAxNy40MSAxMCAxMiAxNS41OCI+PC9wYXRoPjwvc3ZnPg==")
    no-repeat right 16px center;
  background-size: 25px 25px;
  cursor: pointer;
}

.country-select:focus {
  border: 1.5px solid var(--overlay-color);
  outline: none;
}

.floating-label {
  position: absolute;
  top: 14px;
  left: 16px;
  font-size: 14px;
  color: var(--text-primary-color);
  pointer-events: none;
  transition: 0.2s ease all;
}

.select-wrapper.has-value .floating-label,
.country-select:focus + .floating-label {
  top: 8px;
  left: 16px;
  font-size: 12px;
  color: var(--text-secondary-color);
}

.input-container {
  max-width: 400px;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.input-wrapper {
  position: relative;
  display: flex;
  position: relative;
  width: 100%;
}
.country-code-unselected {
  display: none;
}
.country-code-unselected {
  padding: 12px 8px;
  display: none;
  padding-top: 24px;
  border-bottom: 1.5px solid var(--accent-color);
  border-left: 1.5px solid var(--accent-color);
  border-top: 1.5px solid var(--accent-color);
  border-bottom-left-radius: 10px;
}
.country-code-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  padding: 12px 5px 12px 16px;
  padding-top: 22px;
  border-left: 1.5px solid var(--overlay-color);
  border-top: 1.5px solid var(--overlay-color);
  border-bottom: 1.5px solid var(--overlay-color);;
  border-bottom-left-radius: 10px;
  outline: none;
  font-size: 16px;
  color: var(--accent-color);
}
.floating-input {
  width: 100%;
  padding: 12px 5px 12px 16px;

  padding-top: 24px;
  border-bottom: 1.5px solid var(--text-ternary-color);
  border-right: 1.5px solid var(--text-ternary-color);
  border-left: 1.5px solid var(--text-ternary-color);
  border-top: 1.5px solid #faf8f8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 16px;
  /* color: #333333; */
  box-sizing: border-box;
  -webkit-appearance: none;
  margin: 0;
}

.floating-input:focus {
  padding: 12px 0px;
  padding-top: 24px;
  border-bottom-left-radius: 0px;
  border-right: 1.5px solid var(--overlay-color);
  border-top: 1.5px solid var(--overlay-color);
  border-left: 0px solid var(--accent-color);
  border-bottom: 1.5px solid var(--overlay-color);
  outline: none;
}

.floating-label {
  position: absolute;
  top: 14px;
  left: 16px;
  font-size: 14px;
  color: var(--text-secondary-color);
  pointer-events: none;
  transition: 0.2s ease all;
}

.input-wrapper.has-value .floating-label,
.floating-input:focus + .floating-label {
  top: 6px;
  left: 16px;
  font-size: 12px;
  color: var(--accent-color);
}
 input[id="floating-input"]::-webkit-outer-spin-button,
input[id="floating-input"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[id="floating-input"] {
  -moz-appearance: textfield;
}

input[id="floating-input"] {
  -ms-appearance: textfield;
}
@media (max-width: 768px) {
  .login-button {
    padding: 15px 0px;
  }
  .login-container {
    padding: 20px;
    background-color: var(--primary-background-color);
    padding: 5px 20px 20px 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 320px;
    text-align: center;
    outline: none;
    border: none;
  }
  .login-close-button {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .login-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-logo {
    height: 50px;
    width: 130px;
    object-fit: cover;
  }

  .login-container h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .form {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  label {
    text-align: left;
    margin-bottom: 5px;
    font-size: 14px;
  }

  form > select {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid var(--text-ternary-color);
    border-radius: 5px;
    font-size: 14px;
  }
  form > input[type="tel"] {
    width: 90%;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid var(--text-ternary-color);
    border-radius: 5px;
    font-size: 14px;
  }

  .disclaimer {
    font-size: 12px;
    color: var(--text-secondary-color);
    margin-bottom: 20px;
  }

  .privacy-policy {
    color: #007bff;
    text-decoration: none;
  }

  .privacy-policy:hover {
    text-decoration: underline;
  }

  .phone-number-input-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0 auto;
  }

  .react-select__control {
    margin-bottom: 16px;
  }
}
