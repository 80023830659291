/* 

* { box-sizing: border-box; }
body { padding: 20px 0; }
card-padding: 25px;
border-radius: 3px;
featured-color: $blue-01;

h1 { text-align: center; }

.container {
	display: flex;
	margin: 20px auto;
}

.pricingCard {
	border: 1px solid gray-03;
	border-radius: border-radius;
	flex: 1;
	margin: 10px;
	display: flex;
	flex-direction: column;
	padding-bottom: card-padding;
	text-align: center;
}

.pricingCard h2 { margin-bottom: 0; }

.title {
	background: gray-07;
	padding: 20px card-padding;
	border-top-left-radius: border-radius;
	border-top-right-radius: border-radius;
	font-size: 1.7em;
}

.pricingCard.featured {

	border-color: featured-color;
	background: gray-08;
}

.pricingCard.featured .title { 
	background: featured-color;
	color: white;
}

.pricingCard.featured .bttn {
	background: featured-color;
	color: white;

}

.pricingCard .price-overview {
	margin-top: 20px;
	font-weight: bold;
}

.pricingCard.featured .btn { 
	background: blue;
}

.price .price__currency { font-size: 1.7em; padding-right: 3px; }
.price .price__dollar { font-size: 1.7em; }
.price .price__cent { font-size: 1em; }

.card {
	padding: 10px card-padding 20px;
}

.price-desc { 
	font-size: 0.7em;
	margin-top: 0;
}

.bttn { 
	
	display: block; 
	text-align: center;
	
	position: relative;
	margin: 0 card-padding;
	margin-top: auto;
	
	background: none;
	border: 1px solid featured-color;
	color: featured-color;
	

}

.toggle-container { text-align: center; } */

/* body {
  text-align: center;
  text-transform: uppercase;
  padding: 50px 0;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #aaa;
  margin: 20px 0;
  padding: 10px;
  width: 100%;
  min-height: 200px;
  border: 2px #ccc solid;
  color: #fff;
} */

html {
  font-family: sans-serif;
}

body {
  margin: 0;
}

.flexlist {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: grid;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
}
.flexlist-item {
  width: calc(100% / 2 - 5px);
  float: left;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.divToPrint {
  height: 100%;
}
.grid-container {
  transition: margin-top 0.8s ease;
}

.grid-container-hide {
  margin-top: 60px;
}

.grid-container-show {
  margin-top: 0;
}

/* ========mobile view================= */

.comparison-table {
  width: 100%;
  border-collapse: collapse;
}

.comparison-table th,
.comparison-table td {
  border: 1px solid var(--primary-background-color);
  padding: 8px;
}

.comparison-table th {
  background-color: var(--primary-background-color);
}

.comparison-table td {
  border-right: 1px solid var(--text-ternary-color);
}

.comparison-table tr {
  border-right: 1px solid var(--text-ternary-color);
}

.comparison-table tr:last-child {
  border-bottom: none;
}

.product-image {
  width: 100px;
  height: auto;
}

@media (max-width: 768px) {
  .comparison-table th,
  .comparison-table td {
    border: 1px solid var(--text-ternary-color);
    width: 100%;

    padding: 4px;
    font-size: 12px;
  }

  .comparison-table th {
    font-size: 14px;
  }
  .attribute-heading {
    width: 100%;
    text-align: center;
  }
  .product-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }

  .comparison-table {
    width: 100%;

    table-layout: fixed;
   
    border-collapse: collapse;
  }

  .comparison-table th,
  .comparison-table td {
    word-wrap: break-word;
  }
  .fixed-row {
    position: sticky;
    top: 0;
    background-color: var(--primary-background-color);
    z-index: 1;
    border-bottom: none;
  }
  .table-container {
    height: 800px;
    overflow-y: auto;
   
    width: 100%;
  }
  .fixed-header {
    position: sticky;
    top: 0;
    background-color: var(--primary-background-color);
    z-index: 2;
    border-bottom: none;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  }
  .attribute-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200%;
  }
  #fixed-rows-left {
    text-align: left;
    border-right: 0px solid var(--primary-background-color);
    border-bottom: 0px solid var(--primary-background-color);
    border: 0px solid var(--primary-background-color);
    background-color: var(--card-background-color2);
  }
  #fixed-rows-right {
    text-align: left;
    border-right: 0px solid var(--primary-background-color);
    border-bottom: 0px solid var(--primary-background-color);
    border: 0px solid var(--primary-background-color);
    background-color: var(--card-background-color2);
  }

  .hidden-row {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .visible-row {
    transform: translateY(0%);
    transition: transform 0.3s ease-in-out;
  }
  .left-align {
    text-align: left;
    vertical-align: top;
  }

  .right-align {
    text-align: left;
  }
  .center-align {
    text-align: center;
  }

  .image-row {
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
    overflow: hidden;
    max-height: auto;
  }

  .hidden-row {
    opacity: 0;
    max-height: 0;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  }

  .visible-row {
    opacity: 1;
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
    max-height: 100px;
  }
}
