@font-face {
  font-family: "MyCustomFont";
  src: url("../../assets/fonts/Aileron-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.price-details {
  padding: 8px;
  width: 100%;
}
.traveller-info {
  color: var(--text-secondary-color);
  font-weight: normal;
  font-size: 14px;
  margin-left: 5px;
}
.price-details h3 {
  margin: 0 0 10px;
  font-family: "MyCustomFont", sans-serif;
  font-weight: bold;
}

.price-details p {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  font-size: 14px;
  color: var(--text-secondary-color);
}

.price-details p span {
  font-weight: bold;
}

.price-details p .discount {
  color: var(--primary-color);
}

.divider {
  height: 1px;
  background-color: var(--text-ternary-color);
  margin: 10px 0;
}

.to-be-paid {
  display: flex;
  justify-content: space-between;
  font-family: "MyCustomFont", sans-serif;
  font-weight: bold;
}
