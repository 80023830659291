@font-face {
  font-family: "MyCustomFont";
  src: url("../../assets//fonts/Aileron-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.customize-form {
  margin: 0 auto;
  padding: 10px 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  background-color: var(--primary-background-color);
  width: 400px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  box-sizing: border-box;
}
.customize-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  margin-top: 10px;
}
.lead-form-error-text {
  position: absolute;
  color: var(--error-text-color);
  bottom: -20px;
  right: 0;
  left: 0;
  font-size: 10px;
  padding: 0;
  line-height: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customize-submit-button {
  width: 50%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.customize-close-button {
  width: 100%;
  display: flex;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-background-color);
  border-bottom: 1px solid var(--accent-color);
}
.customize-logo-header {
  flex: 4;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.customize-cross-container {
  flex: 4;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}
.customize-form-heading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 25px;
  font-weight: bold;
  font-family: "MyCustomFont", sans-serif;
}
input[id="phone-number"]::-webkit-outer-spin-button,
input[id="phone-number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[id="phone-number"] {
  -moz-appearance: textfield;
}

input[id="phone-number"] {
  -ms-appearance: textfield;
}
@media screen and (max-width: 768px) {
  .customize-form {
    margin: 0 auto;
    padding: 10px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    background-color: var(--primary-background-color);
    width: 310px;
  }
}
