@import "../../../styles//variables.css";
@font-face {
  font-family: "MyCustomFont";
  src: url("../../assets//fonts/Aileron-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "MyCustomFont2";
  src: url("../../assets//fonts/Aileron-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.image-container {
  position: relative;
  width: 93%;
  height: 280px;
  margin-left: 6px;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 10px;
  z-index: 10000;
}

.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: center; */
}

.text-overlay {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.text-overlay h1 {
  margin: 0;
  color: var(--text-primary-color);
  white-space: normal;
  font-size: 1.1rem;
  width: 95%;
  font-family: "CustomFont", sans-serif;
}
.text-overlay p {
  margin: 0;
  width: 95%;
  white-space: normal;
  margin-top: 6px;
  font-size: 0.9rem;
  color: var(--text-secondary-color);
  font-family: "MyCustomFont2", sans-serif;
}
.text-content-overlay {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 10px;
  white-space: normal;
  width: 95%;
}
