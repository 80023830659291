body {
  font-family: "Arial", sans-serif;
  background-color: white;
  padding: 20px;
}

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin: 10px;
  text-align: center;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}
.Demo__container {
  display: grid;
}
