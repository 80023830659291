.confirmation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color:  var(--primary-background-color);
  margin-top: 80px;
}
.confirmation-box {
  background-color:  var(--primary-background-color);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.confirmation-box .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmation-box h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.confirmation-box p {
  margin-bottom: 1rem;
  color: var(--text-secondary-color);
}
.confirmation-box .button {
  background-color: var(--primary-color);
  color: var(--primary-background-color);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
}
@media (max-width: 768px) {
  .confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--primary-background-color);
    margin-top: 20px;
  }
  .confirmation-box {
    background-color: var(--primary-background-color);
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  .confirmation-box p {
    margin-bottom: 1rem;
    color: var(--text-secondary-color);
  }
}
