@import "../../../styles//variables.css";
@font-face {
  font-family: "MyCustomFont";
  src: url("../../assets//fonts/Aileron-SemiBold.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: "MyCustomFont2";
  src: url("../../assets//fonts/Aileron-Regular.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}
.desktop-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.desktop-overlay-heading {
  /* white-space: normal; */
  width: 90%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: "25px";
  color: black;

}


.desktop-text-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.desktop-text-overlay h1 {
  margin: 0;
  font-size: 1.4rem;
  white-space: normal;
  width: 90%;
  font-weight: bolder;
  color: black;
  font-family: "CustomFont", sans-serif;
}
.desktop-text-overlay p {
  margin: 0;
  font-size: 1.1rem;
  width: 90%;
  white-space: normal;
  font-weight: bold;
  margin-top: 12px;
  color: var(--text-secondary-color);
  font-family: "MyCustomFont2", sans-serif;
}
.desktop-text-content-overlay {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 20px;
  white-space: normal;
  width: 95%;
}

/* .desktop-background-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
} */