.banner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000000;
  border-radius: 10px;
}

.popup {
  background-color: var(--primary-background-color);
  /* padding: 20px; */
  border-radius: 8px;
  width: 300px;
  z-index: 100000000;
  text-align: center;
  position: relative;
  height: 90%;
  border-radius: 10px;
}

.closeButton {
  position: absolute;
  top: -10px;
  right: -15px;
  border: none;
  border-radius: 50%;
  padding: 15px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-background-color);
  font-size: 12px;
  cursor: pointer;
}

.actionButton {
  margin-top: 10px;
  padding: 10px 20px;
  color: var(--primary-background-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
