@import "../../../styles//variables.css";
@font-face {
  font-family: "CustomFont";
  src: url("../../assets//fonts/Aileron-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.MuiFormControl-root {
  border: none !important;
  outline: none !important;
  border-color: white !important;
}
.MuiInputBase-input:focus {
  border: none !important;
  outline: none !important;
}
.places-list-suggestion {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
}
.google-autosuggest-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  overflow-y: scroll;
}
.suggestion-list-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 8px 5px;
  /* color: red; */
  /* background-color: #16222b; */

  text-transform: none;
}
.list-container-card {
  position: absolute;
  max-height: 280px;
  top: 30px;
  right: 0;
  width: 100%;
  z-index: 1;
  overflow-y: scroll;
}
.suggestion-list-items:hover {
  background-color: rgb(214, 214, 214);
}
.search-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.icon {
  margin-right: 5px;
  font-size: 18px;
  color: #333;
}

.input-field input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
}

.suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestions li {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.suggestions li:hover {
  background-color: #ddd;
}

/* =========================================================== */
.input-field-text-wrapper {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.input-field-container {
  display: flex;
  height: 90%;
  width: 100%;
  
  align-items: center;

  border-radius: 4px;
  padding: 5px;
  flex: 3;
}
.input-field-icons-wrapper {
  width: 36px;
  height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-field-icons {
  width: 100%;
  height:100%;
  color: grey;
}
.input-field-search {
  width: 100%;
  height: 80%;
  outline: none;
  border: none;
}
.input-field-text {
  font-weight: bold;
  color: var(--text-primary-color);
  font-family: "CustomFont", sans-serif;
}
.suggestion-list-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 8px 5px;
}
.suggestion-list-items:hover {
  background-color: rgb(214, 214, 214);
}

#custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

#custom-scrollbar::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  border-radius: 4px;
}

#custom-scrollbar::-webkit-scrollbar-track {
  background: #d5d3d3;
  border-radius: 4px;
}
.desktop-search-icon {
  padding: 10px;
  color: white;
  border-radius: 50%;
  background-image: linear-gradient(
    to left,
    #f47d2b,
    #f59042,
    #f6a15a,
    #f6b272,
    #f7c28c
  );
}

/* ====================MOBILE VIEW CSS =======================*/

.MuiFormControl-root {
  border: none !important;
  outline: none !important;
  border-color: white !important;
}
.MuiInputBase-input:focus {
  border: none !important;
  outline: none !important;
}
.places-list-suggestion {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
}
.google-autosuggest-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  overflow-y: scroll;
}
.suggestion-list-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 8px 5px;
}
.suggestion-list-items:hover {
  background-color: rgb(214, 214, 214);
}
.mobile-searchbar-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;

  padding: 10px 10px;
  /* justify-content: c; */
}
.mobile-input-field-search {
  width: 85%;
  height: 100%;
  outline: none;
  border: none;
}
.sttings-icon {
  background-image: linear-gradient(
    to left,
    #f47d2b,
    #f59042,
    #f6a15a,
    #f6b272,
    #f7c28c
  );
  color: white;
  padding: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f47d2b;
}
.mobile-modal-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.mobile-topbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1;
  padding: 10px 0px;
}
.mobile-middleContainer {
  flex: 10;
  width: 100%;
}
.mobile-bottombar {
  flex: 1;
  width: 100%;
  z-index: 1000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ==========================mobile========================= */

.MuiFormControl-root {
  border: none !important;
  outline: none !important;
  border-color: white !important;
}
.MuiInputBase-input:focus {
  border: none !important;
  outline: none !important;
}
.places-list-suggestion {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
}
.google-autosuggest-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  overflow-y: scroll;
}
.suggestion-list-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 8px 5px;
}
.suggestion-list-items:hover {
  background-color: rgb(214, 214, 214);
}
.search-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.icon {
  margin-right: 5px;
  font-size: 18px;
  color: #333;
}

.input-field input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
}

.suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestions li {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.suggestions li:hover {
  background-color: #ddd;
}


.suggestions-icons{
  font-size: 18px;
  color:#ff6840 ;
 
}
.suggestions-text{
  font-size: 16px;
  color:#16222b ;
 
}