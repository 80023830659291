/* Navbar styling */

@font-face {
    font-family: "MyCustomFont";
    src: url("../../../assets/fonts/Aileron-Light.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  
    z-index: 1000;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    transition: transform 0.3s ease-in-out;
  }
  .hamburger-menu {
    position: relative;
    display: none;
  }
  
  .navbar ul {
    list-style: none;
    display: block;
    display: flex;
    gap: 1rem;
  }
  
  .navbar ul li {
    cursor: pointer;
    color: var(--primary-background-color);
    font-family: "MyCustomFont", sans-serif;
  }
  
  .menu-toggle {
    display: none;
  }
  
  .menu-icon {
    display: none;
    cursor: pointer;
    color: var(--primary-background-color);
  }
  
  .menu {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    margin-right: 20px;
  }
  
  .navbar-menu {
    display: flex;
    display: none;
    justify-content: space-between;
    align-items: center;
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
  
  .navbar-menu.active {
    max-height: 300px;
  }
  /* hamberger menu */
  /* src/HamburgerMenu.css */
  .hamburger-menu {
    position: relative;
  }
  
  .hamburger {
    display: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }
  
  .hamburger .bar {
    width: 30px;
    height: 3px;
    background-color: var(--text-primary-color);
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
  
  .hamburger.open .bar:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }
  
  .hamburger.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open .bar:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }
  
  .nav-menu {
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: var(--primary-background-color);
    border: 1px solid var(--primary-background-color);
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .nav-menu.open {
    display: block;
  }
  .page-navbar-vertical-line{
    height: 25px;
    width: 1.5px;
    background-color: var(--text-secondary-color);
    /* background-color: red; */
  }
  .nav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-menu ul li {
    padding: 10px 20px;
  }
  
  .nav-menu ul li a {
    text-decoration: none;
    color: var(--text-primary-color);
    display: block;
  }
  
  .nav-menu ul li a:hover {
    background-color: var(--primary-background-color);
  }
  
  .desktop-navigation {
    display: flex;
    align-items: center;
    width: 500px;
  
    justify-content: space-between;
  }
  
  .menu-icon {
    display: none;
    cursor: pointer;
    width: 30px;
    height: 25px;
  }
  
  .menu-icon .bar1,
  .menu-icon .bar2,
  .menu-icon .bar3 {
    width: 100%;
    height: 4px;
    background-color: var(--primary-background-color);
    margin: 6px 0;
    transition: 0.4s;
  }
  .navbar.hidden {
    top: -100px;
  }
  .logo {
    height: 45px;
    width: 140px;
  }
  .single-navigation-div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
  }
  .list-naviation-text-heading {
    font-size: 14px;
    color: var(--text-secondary-color);
    white-space: nowrap;
    margin-left: 5px;
    font-weight: bold;
    /* font-family: "MyCustomFont", sans-serif; */
  }
  .mobile-menu-header {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    outline: none;
    /* background-color: aqua; */
  }
  .mobile-menu-header-content {
    width: 100%;
    height: 120px;
    margin-top: 0px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    background-color: var(--secondary-button-color);
  }
  
  .mobile-drawer-cross-button{
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: end;
    
  }
  
  .mobile-menu-header-button-container {
    height: 50px;
    display: flex;
    border-top: 1px solid var(--primary-background-color);
    align-items: center;
    justify-content: center;
    outline: none;
  
  
  
    width: 100%;
  }
  .mobile-menu-header-logout-button-container {
    display: flex;
    align-items: center;
    justify-content: start;
    outline: none;
    border: none;
    height: 100%;
    width: 90%;
  }
  .mobile-navigation-container {
    margin-top: 10px;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .mobile-navigation-heading {
    cursor: pointer;
    font-size: 15px;
    color: var(--text-primary-color);
    font-family: "MyCustomFont", sans-serif;
  }
  .call-us {
    overflow: hidden;
    color: var(--primary-background-color);
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    /* font-size: 30px; */
    cursor: pointer;
    /* padding: 7px 16px; */
  
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  .mobile-call-us {
    display: none;
  }
  .mobile-login-icon {
    display: none;
  }
  .mobile-whatsapp-call {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .mobile-whatsapp-call {
      display: block;
    }
    .mobile-logout-button {
      font-size: 18px;
      color: rgb(30, 30, 30);
      margin-left: 5px;
      font-family: "MyCustomFont", sans-serif;
    }
    .naviation-text-heading-mobile {
      font-size: 16px;
      color:var(--primary-background-color);
      margin-left: 5px;
      font-family: "MyCustomFont", sans-serif;
    }
    .naviation-text-heading-mobile-loggedin {
      font-size: 16px;
      color: var(--primary-background-color);
      margin-left: 5px;
      padding-top: 4px;
      font-family: "MyCustomFont", sans-serif;
    }
    .drawer-list-items-text{
      margin-left: 10px;
      text-decoration: none;
      color: var(--overlay-color);
      font-size: 14px;
      font-family: "MyCustomFont", sans-serif;
    }
    .drawer-items-list{
      width: 100%;
      display: flex;
      height: 40px;
      align-items: center;
   
      justify-content: start;
    }
    
    .single-navigation-div-mobile {
      width: 90%;
      padding: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: start;
      cursor: pointer;
    }
    .single-navigation-div-mobile-loggedin {
      width: 100%;
      padding: 25px 0px;
      display: flex;
      align-items: center;
      justify-content: start;
      cursor: pointer;
  
    }
    .mobile-login-icon {
      display: block;
    }
    .mobile-call-us {
      overflow: hidden;
      padding: 5px;
      color: var(--primary-background-color);
      display: flex;
      border-radius: 50%;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(
        to left,
        #f47d2b,
        #f59042,
        #f6a15a,
        #f6b272,
        #f7c28c
      );
      cursor: pointer;
      font-weight: bold;
    }
    .mobile-call-us-wrapper {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .mobile-logo {
      height: 55px;
      width: 100px;
    }
    .logo {
      height: 40px;
      width: 122px;
    }
    .navbar {
      position: fixed;
      padding: 15px 20px 0px 20px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: transform 0.3s ease-in-out;
    }
    .hamburger-menu {
      position: relative;
      display: block;
    }
    .navbar ul {
      display: none;
    }
    .navbar-menu {
      position: absolute;
      display: block;
      top: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      flex-direction: column;
      background-color: var(--text-secondary-color);
      padding: 10px 0;
      max-height: 0;
      overflow: hidden;
    }
  
    .navbar-menu.active {
      max-height: 300px;
    }
  
    .navbar-toggle {
      display: flex;
    }
    .menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      background-color: var(--text-secondary-color);
    }
  
    .menu-toggle:checked + .menu {
      display: flex;
    }
  
    .menu-icon {
      display: block;
  
      border-radius: 10px;
    }
    .menu-icon {
      display: block;
      cursor: pointer;
      width: 28px;
      height: 100%;
    }
  
    .menu-icon .bar1,
    .menu-icon .bar2,
    .menu-icon .bar3 {
      width: 100%;
      height: 2px;
      background-color: var(--primary-background-color);
      /* margin: 6px 0; */
      transition: 0.4s;
      border-radius: 10px;
    }
    .menu li {
      margin: 10px 0;
    }
  }
  