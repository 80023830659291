.slider-containers {
    width: 100%;
    position: relative;
  }
  .slick-dots {
    bottom: 10px;
  }
  .slick-dots.custom-dots {
    bottom: -25px; 
  }

  @media (max-width: 768px) {
    .slick-dots {
        display: block;
    }
}
