@font-face {
  font-family: "MyCustomFont";
  src: url("../../../assets/fonts//Aileron-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.complete-profile-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid var(--text-secondary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--primary-background-color);
}
.signup-modal-content {
  background-color: var(--primary-background-color);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
 
}

.topbar-container {
  flex: 4;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}
.signup-error-text {
  color: var(--success-color);

  font-size: 10px;
  padding: 0;
  line-height: 2px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.signup-form-container {
  background-color: var(--primary-background-color);
  padding: 5px 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.signup-form-container h2 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
  font-family: "MyCustomFont", sans-serif;
  text-align: center;
}

.signup-form-container form {
  display: flex;
  flex-direction: column;
}
.optional-text {
  font-size: 10px;
  color: var(--text-secondary-color);
}
.signup-form-container label {
  margin-bottom: 0.2rem;
  font-weight: bold;
  font-size: 14px;
  font-family: "MyCustomFont", sans-serif;
}

.signup-form-container input {
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--text-secondary-color);
  border-radius: 4px;
  font-size: 1rem;
}

@media (max-width: 768px) {
}
