.packageDetails-discount-bar {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 20px;
  background-color: var(--primary-background-color);
  padding: 10px 0px;
  transform: translateY(1px);
}
.packagedeatails-floating-bottom-bar {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--primary-background-color);
 color: white;
 padding: 10px 0px;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .packagedeatails-floating-bottom-bar {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--primary-background-color);
   color: white;
    padding: 10px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  
    z-index: 1000;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
  }
  .packageDetails-discount-bar {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 20px;
    background-color: var(--primary-background-color);
    padding: 5px 0px;
    transform: translateY(1px);
  }
  .grid-container {
    transition: margin-top 0.8s ease;
  }
  
  .grid-container-hide {
    margin-top: 60px;
  }
  
  .grid-container-show {
    margin-top: 0;
  }
}
