@font-face {
  font-family: "CustomFont";
  src: url("../../assets//fonts/Aileron-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.lead-form-container {
  background-color: var(--primary-background-color);
  /* padding: 20px; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  width: 90%;
  display: flex;
  /* align-items: left; */
  flex-direction: column;
  justify-content: center;
}

.lead-form-container h1 {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--text-primary-color);
  font-weight: bolder;
  font-family: "CustomFont", sans-serif;
}

.lead-form-container form {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 8px 0px;
  border: 2px solid var(--secondary-background-color);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.lead-input-group {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.lead-form-container form p {
  font-size: 12px;
  /* width: 90%; */
  color: var(--text-secondary-color);
  /* margin-top: 10px; */
  align-self: flex-start;
  padding: 0px 60px;
}
.homepage-lead-button {
  height: 100%;
  width: 100px;
  padding: 12px 0px;
}
.disabled-homepage-lead-button {
  height: 100%;
  width: 100px;
  padding: 12px 0px;
}
@media screen and (max-width: 768px) {
  .lead-form-container {
    width: 95%;
  }

  .lead-form-container form {
    display: flex;
    /* width: 90%; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 8px 0px;
  }

  .lead-input-group {
    flex-direction: column;
    gap: 20px;
  }

  .lead-form-container h1 {
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: start;
    color: var(--text-primary-color);
    height: 30px;
    font-weight: bolder;
    font-family: "CustomFont", sans-serif;
  }
  .homepage-lead-button {
    height: 100%;
    width: 40%;
    padding: 12px 0px;
  }
  .disabled-homepage-lead-button {
    height: 100%;
    width: 40%;
    padding: 12px 0px;
  }
  .lead-form-container form p {
    font-size: 12px;
    width: 95%;
    text-align: center;
    /* padding: 0px 5px; */
    white-space: normal;
    color: var(--text-secondary-color);
    margin-top: 10px;
    align-self: center;
    padding: 0px 0px;
  }
}
