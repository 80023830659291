/* ContactForm.css */

@font-face {
  font-family: "MyCustomFont";
  src: url("../../assets//fonts/Aileron-Bold.ttf") format("truetype");
  /* font-weight: bold; */
  font-style: normal;
}
.request-call-form {
  margin: 0 auto;
  padding: 10px 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  background-color: var(--primary-background-color);
  width: 400px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 2px;
  font-weight: bold;
  font-family: "MyCustomFont", sans-serif;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  box-sizing: border-box;
}
.request-call-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}
.request-call-submit-button {
  width: 50%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.request-call-close-button {
  width: 100%;
  display: flex;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-background-color);
  border-bottom: 1px solid var(--accent-color);
}
.request-call-logo-header {
  flex: 4;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.request-call-cross-container {
  flex: 4;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}

.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--accent-color);
  border-radius: 4px;
  box-sizing: border-box;
}

.traveller-count {
  display: flex;
  flex-direction: column;
}

.traveller-count-input {
  display: flex;
  width: 100%;
  position: relative;
}
.traveller-count-input input {
  /* text-align: center; */
}

.traveller-count-input .buttons {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-left: none;
  border-left: 0px;
  width: 20px;
  height: 100%;
  cursor: pointer;
}

.traveller-count-input .buttons span {
  color: var(--text-secondary-color);
  border: none;
  border-radius: 0;
  cursor: pointer;

  font-size: 10px;
}

.traveller-count-input .buttons button:first-child {
  border-top-right-radius: 4px;
}

.traveller-count-input .buttons button:last-child {
  border-bottom-right-radius: 4px;
}
.tour-header {
  width: 100%;
  height: 70px;
  display: flex;

  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.tour-image {
  width: 65px;
  height: 60px;

  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}
.tour-details {
  flex: 10;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.tour-details h2 {
  margin: 0;
  font-size: 12px;
  /* font-weight: bold; */
  /* font-family: "MyCustomFont"; */
  color: var(--text-primary-color);
}

.tour-pricing {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 8px;
  color: var(--text-primary-color);
  /* font-family: "MyCustomFont", sans-serif; */
}

.current-price {
  font-size: 12px;
  font-weight: bold;
  color: var(--text-primary-color);
  /* font-family: "MyCustomFont", sans-serif; */
}

.original-price {
  font-size: 12px;
  text-decoration: line-through;
  color: var(--text-secondary-color);
  margin-left: 10px;
  font-weight: bold;
  /* font-family: "MyCustomFont", sans-serif; */
}

.discount {
  font-size: 12px;
  color: var(--success-color);
  margin-left: 10px;
  font-weight: bold;
  /* font-family: "MyCustomFont", sans-serif; */
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"] {
  -ms-appearance: textfield;
}
input[id="phone-number"]::-webkit-outer-spin-button,
input[id="phone-number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[id="phone-number"] {
  -moz-appearance: textfield;
}

input[id="phone-number"] {
  -ms-appearance: textfield;
}
.request-lead-form-error-text {
  position: absolute;
  color: var(--error-text-color);
  bottom: -15px;
  right: 0;
  left: 0;
  font-size: 10px;
  padding: 0;
  line-height: 2px;
  display: flex;
  align-items: center;
  justify-content: start;
}
@media screen and (max-width: 768px) {
  .request-call-form {
    margin: 0 auto;
    padding: 10px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    background-color: var(--primary-background-color);
    width: 310px;
  }
}
