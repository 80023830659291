.sale-banner-container {
  width: 90%;
  border-radius: 40px;
}
@media (max-width: 768px) {
  .sale-banner-container {
    width: 95%;
    border-radius: 40px;
  }
}
