.discount-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-background-color);
  border-bottom: 1px solid var(--text-secondary-color);
  border-right: 1px solid var(--text-secondary-color);
  border-left: 1px solid var(--text-secondary-color);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  height: 30px;
  transform: translateY(1px);
}

.discount-text {
  font-size: 14px;
  color: var(--primary-black);
  font-style: italic;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}
.discount-text .login-button {
  background-color: var(--primary-color);
  color: var(--primary-background-color);
  border: none;
  padding: 10px;
  /* padding: 10px 20px; */
  font-size: 10px;
  width: 80px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 5px;
}
.red-builb-emoji{
  margin-left: 5px;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .discount-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-background-color);
    border-bottom: 1px solid var(--text-secondary-color);
    border-right: 1px solid var(--text-secondary-color);
    border-left: 1px solid var(--text-secondary-color);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  
    height: 30px;
    transform: translateY(1px);
  }
  .discount-text .login-button {
    background-color: var(--primary-color);
    color: var(--primary-background-color);
    border: none;
    padding: 14px;
    /* padding: 10px 20px; */
    font-size: 10px;
    width: 80px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
  }
  .red-builb-emoji{
    margin-left: 5px;
    font-size: 14px;
  }
}