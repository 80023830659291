.success-image-container {
  width: 100%;
  height: 100%;
}
.success-image {
  width: 100%;
  height: 200px;
}
.success-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
}
.success-heading-text {
  line-height: 2px;
}
.success-heading-sub-text {
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;

}
