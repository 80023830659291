@font-face {
  font-family: "MyCustomFont";
  src: url("../../../assets/fonts//Aileron-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.otp-container {
  position: relative;
  display: flex;
  gap: 8px;
}

.otp-input {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  border: 1px solid var(--text-secondary-color);
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.otp-input:focus {
  border-color: var(--text-primary-color);

}

.modal-content {
  background-color: var(--primary-background-color);
  padding: 5px 20px 20px 20px;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
  font-family: "MyCustomFont", sans-serif;
}

.modal-content p:first-of-type {
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--text-secondary-color);
  font-family: "MyCustomFont", sans-serif;
  padding-right: 20px;
  padding-left: 20px;
}
.otp-login-button {
  margin-top: 20px;
  width: 50%;
}
.topbar-container {
  flex: 4;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
}
.otp-error-text {
  width: 100%;
  white-space: normal;
  position: absolute;
  color: var(--error-text-color);
  bottom: -25px;
  right: 0;
  left: 0;
  font-size: 12px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-inputs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.resend {
  margin-top: 20px;
  color: var(--text-secondary-color);
}

input[id="otp-input"]::-webkit-outer-spin-button,
input[id="otp-input"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[id="otp-input"] {
  -moz-appearance: textfield;
}

input[id="otp-input"] {
  -ms-appearance: textfield;
}
@media (max-width: 768px) {
  .otp-inputbox {
    width: 100%;
  }

  .modal-content {
    background-color: var(--primary-background-color);
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    max-width: 320px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  .modal-content h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: normal;
  }

  .modal-content p:first-of-type {
    width: 100%;
    margin-bottom: 20px;
    color: var(--text-secondary-color);
  }

  .otp-inputs {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .resend {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: var(--text-secondary-color);
  }
}
