.home-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.customize-form {
  width: 200px;
}
.content-placeholder {
  width: 100vw;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.shimmer,
.shimmer-text {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #f0f0f0 40%, #f0efef 40%, #cdcccc 80%);
  background-size: 200% 100%;
  animation: shimmerAnimation 2s infinite linear;
}

.text-placeholder {
  margin-top: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shimmer-text {
  height: 100vh;
  margin-bottom: 5px;
}

@keyframes shimmerAnimation {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.loader-home-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-widgets-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.widgets-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.full-page-shimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.scroll-to-lead-form {
  position: fixed;
  bottom: 80px;
  right: 20px;
  font-size: 20px;
  padding: 20px;
  background-color: var(--secondary-button-color);
  color: var(--primary-background-color);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  z-index: 12000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.sticky-whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 15px;
  border: none;
  cursor: pointer;
  z-index: 12000;

  background-color: transparent;
}
.homepage-whatsapp-call{
  width: 40px;
  height: 40px;

}
@media (max-width: 768px) {
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 16px;
    width: 100%;
    height: 100%;
  }
  .home-widgets-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 10px;
  }
  .widgets-container {
    width: 100%;
    height: auto;
  }
  .scroll-to-lead-form {
    position: fixed;
    bottom: 70px;
    right: 20px;
    font-size: 20px;
    padding: 10px;
    font-weight: bolder;
    background-color: var(--secondary-button-color);
    color: var(--primary-background-color);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    z-index: 6000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  .sticky-whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 15px;
    border: none;
    cursor: pointer;
    z-index: 6000;
  
    background-color: transparent;
  }
  .homepage-whatsapp-call{
    height: 35px;
    width: 35px;
  
  }
}
