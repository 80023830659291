
.c5-main-container{
    width: 90%;
    height: 100%;
    /* z-index: 100000; */
 

 
}
@media (max-width: 768px) {
    .c5-main-container{
        width: 95%;
        height:100%;
   
      
    }
}
