@import "../../../styles//variables.css";
@font-face {
  font-family: "CustomFont";
  src: url("../../assets//fonts/Aileron-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.MuiFormControl-root {
  border: none !important;
  outline: none !important;
  border-color: white !important;
}
.MuiInputBase-input:focus {
  border: none !important;
  outline: none !important;
}
.places-list-suggestion {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
}
.google-autosuggest-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  overflow-y: scroll;
}
.suggestion-list-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 8px 5px;
  z-index: 1000;
}
.suggestion-list-items:hover {
  background-color: rgb(214, 214, 214);
}
.mobile-suggestions-icons {
  font-size: 16px;
  color: #ff6840;
}
.mobile-suggestions-text {
  font-size: 16px;
  color: #16222b;
}
.mobile-searchbar-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;

  padding: 10px 10px;
  /* justify-content: c; */
}
.mobile-input-field-search {
  width: 85%;
  height: 100%;
  outline: none;
  border: none;
  padding: 5px 0px;
  font-size: 17px;
}
.search-icon {
  color: var(--text-secondary-color);

  padding: 10px;
  height: 24px;
  width: 24px;
}
.sttings-icon {
  background-image: linear-gradient(
    to left,
    #f47d2b,
    #f59042,
    #f6a15a,
    #f6b272,
    #f7c28c
  );
  color: white;
  padding: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f47d2b;
}
.mobile-list-container-card {
  position: absolute;
  top: 25px;
  right: 0;
  width: 100%;
  z-index: 1000;
  max-height: 250px;
  overflow-y: scroll;
  background-color: violet;
}
.mobile-modal-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.mobile-topbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 10px 0px;
}
.mobile-middleContainer {
  flex: 10;
  width: 100%;
}
.clear-all-text {
  font-size: 20px;
  font-weight: bold;
}
.mobile-bottombar {
  flex: 1;
  width: 100%;
  /* z-index: 1000; */
  padding: 10px 0px;
  z-index: 1;
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ==========================mobile========================= */

.MuiFormControl-root {
  border: none !important;
  outline: none !important;
  border-color: white !important;
}
.MuiInputBase-input:focus {
  border: none !important;
  outline: none !important;
}
.places-list-suggestion {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
}
.google-autosuggest-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  overflow-y: scroll;
}
.suggestion-list-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 8px 5px;
}
.suggestion-list-items:hover {
  background-color: rgb(214, 214, 214);
}
.search-bar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
}

.icon {
  margin-right: 5px;
  font-size: 18px;
  color: #333;
}

.input-field input {
  flex: 1;
  border: none;
  outline: none;
  padding: 5px;
}

.suggestions {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestions li {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.suggestions li:hover {
  background-color: #ddd;
}

/* =========================================================== */
.mobile-input-field-text-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  /* padding: 10px 0px; */
  flex-direction: column;
}

.mobile-input-field-container {
  display: flex;

  width: 90%;

  align-items: center;

  padding: 10px;

  border-bottom: 1px solid rgb(193, 192, 192);
}
.mobile-input-field-icons-wrapper {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-input-field-icons {
  width: 65%;
  height: 65%;
}

.mobile-input-field-text {
  font-weight: bolder;
  font-size: 18px;
  color: var(--text-primary-color);
  font-family: "CustomFont", sans-serif;
}
.suggestion-list-items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  padding: 8px 5px;
}
.suggestion-list-items:hover {
  background-color: rgb(214, 214, 214);
}

#custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

#custom-scrollbar::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  border-radius: 4px;
}

#custom-scrollbar::-webkit-scrollbar-track {
  background: #d5d3d3;
  border-radius: 4px;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-slide-down {
  animation: slideDown 0.5s ease forwards;
}
.animate-slide-up {
  animation: slideUp 0.5s ease forwards;
}
@keyframes popupAnimation {
  from {
    opacity: 0;
    transform: translateY(0px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-popup-animation {
  animation: popupAnimation 0.5s ease forwards;
}
.mobile-input-search-text {
  font-size: 14px;
  padding: 0px;
  font-weight: bold;
  line-height: 0px;
  padding: 0%;
}
