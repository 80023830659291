
@font-face {
    font-family: "CustomFont";
    src: url("../../assets//fonts/Aileron-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
.nointernet-container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
}
.nointernet-mesaage{


    font-family: "CustomFont", sans-serif;


}
.nointernet-heading{
    font-family: "CustomFont", sans-serif;
}
@media (max-width: 768px) {
    .nointernet-container{
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      
    }
    .nointernet-mesaage{
        width: 80%;
        text-align: center;
        font-family: "CustomFont", sans-serif;
   
    
    }
}