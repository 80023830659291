.fc-daygrid-day.event-day {
  color: var(--primary-black);
  position: relative;
}

.fc-daygrid-day.event-day .fc-daygrid-day-top {
  color: var(--primary-black);
  font-weight: bold;
}
.loading-container{
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fc-daygrid-day.event-day .fc-daygrid-day-events {
  padding-top: 5px;
}
.fc-daygrid-event {
  cursor: pointer;
}
.fc-day-disabled {
  background-color: var(--primary-background-color);
  cursor: not-allowed;
  pointer-events: none;
  /* color: red !important; */

}


.fc-day-disabled {
  background-color: lightgrey;
  color: #a0a0a0;
}

.green-cell {
  background-color: var(--box-color) !important;
  color: var(--primary-black);
  cursor: pointer;
}
.dates-dot {
  width: 10px;
  height: 10px;
  background-color: var(--box-color);
  border-radius: 50%;
  margin-right: 5px;
}
.calendar-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.calendar-heading-container div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* .fc-day-disabled {
  background-color: var(--primary-background-color);
  cursor: not-allowed;
} */

.disabled-cell {
  background-color: var(--primary-background-color);
  color: var(--text-ternary-color);
}
.fc-daygrid-day.fc-day-othermonth {
  visibility: hidden;
}

.fc-daygrid-day.fc-day-othermonth .fc-daygrid-day-number {
  visibility: hidden;
}
.fc-daygrid-day {
  padding-right: 5px !important;
}
.fc-daygrid-day-number {
  font-size: 18px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .fc {
    height: 100%;
    /* height: 500px; */
  }
  .fc-event {
    z-index: 10;
    /* height: 10px !important; */
  }

  .fc-daygrid-day {
    z-index: 5;
    /* height: 10px !important; */
  }

  .fc-toolbar-title {
    font-size: 10px;
  }
  .loading-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
  }
}
