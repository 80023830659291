body {
  margin: 0;
}
.filter-wrapper {
  /* width: 30%; */
  height: 100%;
  /* height: 200vh; */
  padding: 5px;
  /* overflow-y: scroll; */
}
.selected-chips {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  flex-wrap: wrap;
}
.page {
  display: grid;
  grid-template-rows: 55px auto;
  grid-template-columns: 3.5rem auto;
  grid-template-areas: "nav header" "nav content";
}
.packagelist-discount-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-button-color);
  /* border-bottom: 1px solid var(--text-secondary-color);
  border-right: 1px solid var(--text-secondary-color);
  border-left: 1px solid var(--text-secondary-color); */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 10px;
  height: 30px;
  transform: translateY(1px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.nav {
  grid-area: nav;
  background-color: blue;
}

.nav > div {
  position: sticky;
  top: 0;
}

.header {
  grid-area: header;
  background-color: grey;
  position: sticky;
  top: 0;
  min-height: 3.5rem;
}

.content {
  grid-area: content;
  min-height: 1000px;
}
.floating-bottom-bar {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); */
  padding: 10px;
  text-align: center;
  z-index: 1000;
}
.floating-bottom-bar1 {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--text-secondary-color);
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}
.desktop-floating-bottom-bar {
  position: fixed;
  bottom: 7px;
  right: 50px;
  /* width: 50%; */
  /* border: 2px solid var(--primary-black); */
  background-color: rgba(14, 13, 13, 0.25);
  border-radius: 15px;
  color: white;
  text-align: center;
  z-index: 1000;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
}
@media screen and (max-width: 768px) {
  .filter-mobile-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    outline: none;
  gap: 5px;
  font-size: 18px;
  text-decoration: underline;
  background-color: var(--primary-background-color);
    /* width: 20px; */
    border: 1px solid var(--text-secondary-color);
  }
  .packagelist-discount-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-button-color);
    /* border-bottom: 1px solid var(--text-secondary-color);
    border-right: 1px solid var(--text-secondary-color);
    border-left: 1px solid var(--text-secondary-color); */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 10px 0px;
    transform: translateY(1px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .floating-bottom-bar1 {
    display: block;
    position: fixed;
    
    bottom: 45px;
    left: 0;
    width: 100%;
    background-color: var(--text-secondary-color);
    color: var(--primary-background-color);
    /* padding: 10px; */
    height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    font-size: 14px;
  }
  .filters-floating-bottom-bar {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--primary-background-color);
    /* color: ; */
    /* padding: 10px 0px; */
    height: 45px;
    text-align: center;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
  }
  .floating-bottom-bar {
    background-color: var(--primary-background-color);
    display: block;
    position: fixed;
    bottom: 38px;
    left: 0;
    width: 100%;

    padding: 10px;
    text-align: center;
    z-index: 1000;
  }
  .floating-bottom-bar button {
    background-color: #0b3954;
    color: var(--primary-background-color);
    border: none;
    padding: 10px 60px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
}
