@import "../../../styles//variables.css";
@font-face {
  font-family: "MyCustomFont";
  src: url("../../assets//fonts/Aileron-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "CustomFont";
  src: url("../../assets//fonts/Aileron-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.testimonial-component-heading-wrapper {
  width: 100%;
  display: flex;
  /* height: 100%; */
  justify-content: start;
  align-items: center;

}
.testimonial-component-text {
  font-size: 40px;
  font-weight: bold;
  font-family: "CustomFont", sans-serif;
  color: var(--secondary-color);
}
.testimonial-component-container {
  width: 90%;
  border-radius: 10px;
  height: 100%;

}
.border-line {
  width: 15%;
  margin-top: 10px;
  height: 5px;
  background-color: #ffb400;
  border-radius: 5px;
  outline: none;
  border: none;
}

@media (max-width: 678px) {
  .testimonial-component-heading-wrapper {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .border-line {
    width: 15%;
    margin-top: 10px;
    height: 3px;
    background-color: #ffb400;
    border-radius: 5px;
    outline: none;
    border: none;
  }
  .testimonial-component-container {
    width: 95%;
    padding: 5px 10px;
    box-shadow: 0px 0px 48px 14px rgba(0, 0, 0, 0);
    /* margin-top: 25px; */
  }
}
