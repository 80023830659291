@font-face {
  font-family: "MyCustomFont";
  src: url("../../assets/fonts/Aileron-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.container {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: auto;
  margin-top: 75px;
  min-height: 80vh;
}
.left-box-container {
  background-color: var(--primary-background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
}
.booking-summary-container {
  background-color: var(--primary-background-color);
  border: 1px solid var(--accent-color);
  border-radius: 10px;
  padding: 0px 10px;
  width: 100%;
}
.booking-price-container {
  background-color: var(--primary-background-color);
  border: 1px solid var(--accent-color);
  border-radius: 10px;
  padding: 0px 10px;
  width: 100%;
  z-index: 1000;
}
.booking-terms-container {
  background-color: var(--primary-background-color);
  border: 1px solid var(--accent-color);
  border-radius: 10px;
  padding: 0px 10px;
  width: 100%;
  margin-top: 10px;
  z-index: -1;
}
.booking-data-row {
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
  height: auto;
  margin: 0;
  line-height: 0px;
  width: 100%;
  font-family: "MyCustomFont", sans-serif;
  color: var(--text-primary-color);
}
.booking-data {
  margin-left: 3px;
  font-family: "MyCustomFont", sans-serif;
}
.desktop-booking-data-row {
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
  height: auto;
  margin: 0;
  line-height: 1;
  width: 100%;
  font-family: "MyCustomFont", sans-serif;
  color: var(--text-primary-color);
}
.desktop-content-wrapper1 {
  display: flex;
  flex: 6;
}
.desktop-content-wrapper2 {
  display: flex;
  flex: 6;
  justify-content: flex-end;
}
.booking-heading-title {
  font-family: "MyCustomFont", sans-serif;
  margin-left: 5px;
  font-weight: bold;
}
.booking-terms-list-title {
  font-family: "MyCustomFont", sans-serif;
  font-size: 10px;
  font-weight: bold;
}
.booking-note {
  margin: 0px;
  padding: 5px 5px;
  font-size: 10px;
  font-family: "MyCustomFont", sans-serif;
  color: var(--text-primary-color);
}
.container section {
  padding: 20px;

  background-color: var(--primary-background-color);
}

.container form {
  display: grid;
  gap: 15px;
}

.price-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  padding: 20px;
  height: calc(100vh - 260px);
}

.price-box button {
  background-color: var(--primary-color);
  color: var(--primary-background-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  margin: 10px 0px;
}

ul {
  padding-left: 20px;
}

.traveller-counter {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 10px;
  font-family: "MyCustomFont", sans-serif;
}

.input-group {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--text-secondary-color);
}

.input {
  width: 40px;
  text-align: center;
  padding: 5px 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn {
  color: var(--primary-background-color);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 2px;
  color: var(--secondary-button-color);
}

.btn.increment {
  border-radius: 0px 0 0 0px;
}

.btn.decrement {
  border-radius: 0 4px 4px 0;
}
.booking-floating-bottom-bar {
  display: none;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    max-width: 100%;
    margin: auto;
    margin-top: 70px;

    min-height: 100%;
  }
  .booking-summary-container {
    background-color: var(--primary-background-color);
    border: 1px solid var(--text-secondary-color);
    border-radius: 10px;
    padding: 0px 5px;
    width: 100%;
  }
  .price-box {
    position: static;
    background-color: var(--primary-background-color);
    padding: 5px 20px;
    width: 90%;
    display: flex;
    height: 100%;
    z-index: 100;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  .left-box-container {
    background-color: var(--primary-background-color);
  }
  .booking-summary-container {
    background-color: var(--primary-background-color);
    border-radius: 10px;
    padding: 0px 10px;
    width: 100%;
  }
  .booking-floating-bottom-bar {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--primary-background-color);
    color: white;
    padding: 0px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  .booking-floating-bottom-bar .booking-price-container {
    background-color: var(--primary-background-color);
    border: none;
    border-radius: 0px;
    padding: 0px 10px;
    width: 100%;
    line-height: 10px;
    margin-top: 0px;
    box-shadow: none;
  }
  .booking-terms-container {
    background-color: var(--primary-background-color);
    border-radius: 10px;
    padding: 0px 10px;
    width: 100%;
    margin-top: 0px;
  }
  .booking-floating-bottom-bar .booking-price-container button {
    background-color: var(--primary-color);
    color: var(--primary-background-color);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    margin: 10px 0px;
  }
  .booking-heading-title {
    font-family: "MyCustomFont", sans-serif;
    margin-left: 5px;
    font-weight: bold;
    color: var(--primary-black);
  }
  .booking-note {
    margin-bottom: 10px;
    padding: 0px 0px;
    font-size: 10px;
    text-align: start;
    font-family: "MyCustomFont", sans-serif;
    color: var(--text-primary-color);
  }
}
