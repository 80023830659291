body {
  margin: 0;
  padding: 0;
  /* font-family: "Lato"; */
  font-family: "Open Sans";

  
  /* -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: local("Aileron-Bold"),
    url("../src/app/assets/fonts/Aileron-Bold.ttf") format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: local("Aileron-Light"),
    url("../src/app/assets/fonts/Aileron-Light.ttf") format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: local("Aileron-Regular"),
    url("../src/app/assets/fonts/Aileron-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: local("Aileron-SemiBold"),
    url("../src/app/assets/fonts/Aileron-SemiBold.ttf") format("opentype");
}
