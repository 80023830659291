@font-face {
  font-family: "MyCustomFont";
  src: url("../../../assets/fonts/Aileron-Light.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.filter-container {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 0px 5px;

}
.filter-data-containers {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.filters-skelton {
  background-color: var(--card-background-color);
}
.filters-top-heading {
  margin: 0px;
  padding: 0px;
  line-height: 0px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
}
.filter-heading {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  padding: 5px 5px;
  font-family: "MyCustomFont", sans-serif;
  border-top: 1px solid var(--accent-color);
  font-size: 14px;
}
.filter-option {
  font-family: "MyCustomFont", sans-serif;
  color: var(--text-secondary-color);
  font-size: 14px;
}
.clear-all {
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
  font-family: "MyCustomFont", sans-serif;
  cursor: pointer;
}

/* ====================================mobile filters========= */

.mobile-filter-heading {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500px;
  padding: 5px 5px;
  font-family: "MyCustomFont", sans-serif;
  height: 100%;
  font-size: 14px;
}
.mobile-filter-header {
  position: sticky;
  top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  z-index: 1000;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: white;
  border-bottom: 1px solid var(--card-bg-color);
}



.mobile-filter-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex: 1
}

.filter-section {
  background-color: var(--primary-background-color);
  border-radius: 5px;
}

.filter-item {
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
}

.mobile-filter-options {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 300px;
}

.filter-options label {
  display: block;
  padding: 10px 0;
  cursor: pointer;
}
.mobile-filters-bottom-bar {
  margin-top: 5px;
  padding: 10px 10px;
  background-color: var(--primary-background-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
}
.filter-button {
  flex: 6;
  background-color: var(--primary-color);
  color: var(--primary-background-color);
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
}
.mobile-clear-button {
  flex: 6;
  background-color: var(--primary-background-color);
  color: var(--primary-black);
  border: 1px solid var(--primary-black);
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}
.mobile-filter-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
  height: 100%;
}
.mobile-left-options-containers{
  height: 100%;
  flex: 5;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  overscroll-behavior: contain;
}
.mobile-right-option-containers{
  height: 100%;
  flex: 7;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  overscroll-behavior: contain;
}
.mobile-filter-options {
  max-height: 200px; 
  overflow-y: auto;
}

.mobile-filter-option {
  padding: 10px;
  border-bottom: 1px solid var(--card-bg-color);
}
@media screen and (max-width: 768px) {
  .dialogue-text {
    font-size: 18px;
    padding: 10px;
    font-family: "MyCustomFont", sans-serif;
  }
  .dialogue-continue {
    font-size: 16px;
    padding: 10px;
    font-family: "MyCustomFont", sans-serif;
    background-color: var(--primary-color);
    color: var(--primary-background-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 6;
  }
  .dialogue-cancel {
    font-size: 16px;
    padding: 10px;
    font-family: "MyCustomFont", sans-serif;
    background-color: var(--primary-background-color);
    color: var(---text-primary-color);
    border: 1px solid var(--primary-black);
    border-radius: 5px;
    cursor: pointer;
    flex: 6;
  }
  .clear-all {
    text-decoration: underline;
    font-weight: bold;
    font-size: 10px;
    width: 40px;
    font-family: "MyCustomFont", sans-serif;
  }
  .filter-text-heading {
    color: var(--accent-color);
    margin-bottom: 5px;
    font-size: 14px;
  }
}

