@import "../../../styles//variables.css";
@font-face {
  font-family: "MyCustomFont";
  src: url("../../assets//fonts/Aileron-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.testimonial-main-container {
  width: 100%;
  height: 180px;
  margin-top: 10px;

 
  
}

.testimonial-content-main {
  flex: 10;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* padding: 2px; */
}

.testimonial-main-wrapper {
  display: flex;

  justify-content: space-between;
  align-items: center;

  margin: 3px;




}
.testimonial-image-wrapper {
  flex: 2;
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: start;


}
.testimonial-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  padding: 3px;
  border: 3px solid var(--secondary-button-color);
}
.testimonial-heading-rating-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.testimonial-header-content1 {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.testimonial-header-content2 {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.testimonial-ratings {
  font-size: 15px;
}
.testimoniaal-name {
  font-size: 16px;
}
.testimoniaal-data {
  font-size: 14px;

  line-height: 3px;
  color: var(--text-secondary-color);
}
.testimonial-reading {
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: end;
  

}
.testimonial-reading-text {
  font-size: 12px;
  font-weight: bold;
  color: var(--secondary-button-color);
 

}
.testimonial-text {
  position: relative;
  font-size: 14px;
  width: 100%;
  color: var(--text-secondary-color);
}
.testimonial-context-text1 {
  width: 100%;

  display: flex;

  justify-content: start;

  align-items: center;
}

@media (max-width: 678px) {
  
.testimonial-content-main {
  /* flex: 10; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}
  .testimonial-main-container {
    width: 100%;
    height: 100%;
    margin-top: 0px;
   
  }
  .grid-item-testimonial {
    padding: 0px !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    margin-top: 10px !important;
    border-radius: 5px !important;
 
 
  }
  .testimonial-main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
 
    gap: 0px;
  }
  .testimonial-image-wrapper {
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;

  
  }
  .testimonial-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 3px;
    border: 3px solid var(--secondary-button-color);
  }
  .testimonial-heading-rating-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .testimonial-header-content1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .testimonial-header-content2 {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;

  }
  .testimonial-ratings {
    font-size: 14px;
  }
  .testimoniaal-name {
    font-size: 16px;
  }
  .testimoniaal-data {
    font-size: 14px;

    line-height: 3px;
    color: var(--text-secondary-color);
  }
  .testimonial-reading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: -15px;
   

  }
  .testimonial-reading-text {
    font-size: 12px;
    font-weight: bold;
    color: var(--secondary-button-color);
  }
  .testimonial-text {
    font-size: 14px;

    color: var(--text-secondary-color);
  }
}
